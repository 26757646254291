import React from 'react';

// Components
import { CallNotifications } from '../modules/call-notifications/call-notifications';

// Styles
import './status-in-call-no-payment.scss';

export const StatusInCallNoPayment = () => {

  const workerId = localStorage.getItem('worker_id');
  const returnPathname = localStorage.getItem('return_pathname') ?? `/expert/${workerId}`;

  return (<CallNotifications
    title={'Consultant Unavailable'}
    text={'Unfortunately, the Consultant has already started another call. You may try to contact them later.'}
    navigateAction={returnPathname}  />);
}
