import React from 'react';

// Components
import { CallNotifications } from '../modules/call-notifications/call-notifications';

// Styles
import './status-in-call.scss';

export const StatusInCall = () => {

  const workerId = localStorage.getItem('worker_id');
  const returnPathname = localStorage.getItem('return_pathname') ?? `/expert/${workerId}`;

  return (<CallNotifications
    title={'Consultant Unavailable'}
    text={'Unfortunately, the Consultant has already started another call. You may try to contact them later. The payment for the Consultation will be refunded to you.'}
    navigateAction={returnPathname}  />);
}
