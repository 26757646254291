import React from 'react';

// Styles
import './messages-empty.scss';

export const MessagesEmpty = () => {

  return (<div className="messages--body-empty">
    <div className="empty-img">
      <img src={ '/assets/icons/big/correspondence.svg' } />
    </div>
    <div className="empty-subtitle">
      Write your request to the Consultant to start conversation
    </div>
  </div>);
}
