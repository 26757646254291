import { FC, Fragment, ReactNode, SyntheticEvent, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { useNavigate, useSearchParams } from 'react-router-dom';

// Shared UI Library Components
import { InfoMessage } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './history-list.scss';

// Components
import { Consultation } from '../consultation/consultation';
import { Request } from '../request/request';
import { HistoryNotification } from '../history-notification/history-notification';
import { CallNotification } from '../call-notification/call-notification';
import { ConversationConsultation } from '../conversation-consultation/conversation-consultation';
import { TextChatNotification } from '../text-chat-notification/text-chat-notification';
import { AppointmentNotification } from "../appointment-notification/appointment-notification";

// Models
import { IHistory } from '../../../../interfaces/history.interfaces';
import {
  IAppointmentHistory,
  ICallNotification,
  IConversationHistory,
  IConversationNotification,
  IMessageNotification
} from '../../../../interfaces/chat.interfaces';

interface IHistoryListProps {
  history: Array<IHistory | IConversationHistory | IMessageNotification | ICallNotification | IAppointmentHistory>;
  total: number;
  isActiveCounsellor: boolean;
  loading?: boolean;
  isLoad?: boolean;
  loadMore?: () => void;
  resendMessage?: (history: IHistory) => void;
}

export const HistoryList: FC<IHistoryListProps> = (
  {
    history,
    total,
    isActiveCounsellor,
    loading = false,
    isLoad = false,
    loadMore = () => {},
    resendMessage = () => {}
  }: IHistoryListProps
) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  const transformDatesObject: {
    [key: string]: Array<IHistory | IConversationHistory | IMessageNotification | ICallNotification | IAppointmentHistory>
  } = useMemo(() => {
    const uniqueDates = Array.from(new Set(history.map((history) => dayjs(history.created_at).format('DD MMM YYYY'))));
    return uniqueDates.reduce((acc, date) => {
      acc[date] = history.filter(item => dayjs(item.created_at).isSame(date, 'day'));
      return acc;
    }, {} as {
      [key: string]: Array<IHistory | IConversationHistory | IMessageNotification | ICallNotification | IAppointmentHistory>
    });
  }, [history]);

  const getDateTitle = (date: string): string => {
    if (dayjs(date).isSame(dayjs(), 'day')) {
      return 'Today';
    }
    if (dayjs(date).isSame(dayjs().subtract(1, 'day'), 'day')) {
      return 'Yesterday';
    }
    return date;
  }

  const onScrollListHandler = (event: SyntheticEvent): void => {
    const { scrollTop, scrollHeight, offsetHeight } = event.target as HTMLDivElement;
    if ((Math.abs(scrollTop) + offsetHeight + 50 < scrollHeight) || loading || total <= history.length) return;
    loadMore();
  }

  const onResendMessageHandler = (history: IHistory): void => {
    resendMessage(history);
  }

  const onViewHandler = ({ worker, message_pinged }: IMessageNotification): void => {
    navigate(`/expert/${ worker?.id }/messages?after=${ message_pinged?.messages_after }&id=${ message_pinged?.id }`);
  }

  const getInfoMessage = (history: IHistory): ReactNode => {
    if (history.is_replied) return '';

    const expiredDays = dayjs(history.finished_at).diff(history.created_at, 'hours');
    const expiredString = `${ expiredDays } ${ expiredDays !== 1 ? 'hours' : 'hour' }`;
    const isExpired = dayjs().isAfter(history.finished_at);
    let infoMessage = '';
    if (isExpired) {
      infoMessage = `The Consultant did not respond to your message within <b>${ expiredString }</b>, so the refund has been processed. If you would like, you may try contacting the Consultant again.`;
    } else {
      infoMessage = `The Consultant has a period of <b>${ expiredString }</b> to provide you with an answer. If the answer is not provided within this timeframe, the full amount of money you spent on the message will be refunded to you.`;
    }
    return <InfoMessage infoMessage={ infoMessage }/>;
  }

  const getComponentByType = (item: IHistory | IConversationHistory | IMessageNotification | ICallNotification | IConversationNotification | IAppointmentHistory): ReactNode => {
    if (item.hasOwnProperty('timezone')) {
      return <AppointmentNotification id={ `${ item.id }` } history={ item as IAppointmentHistory }/>
    }
    if (item.consultation_type === 'message' && item.hasOwnProperty('message_pinged')) {
      return <HistoryNotification id={ `${ item.id }` } isRedirectItem={ true }
                                  messageInfo={ item as IMessageNotification }
                                  onScrollToMessage={ onViewHandler }/>
    }
    if (item.consultation_type.includes('call') && item.hasOwnProperty('call_pinged')) {
      return <CallNotification id={ `${ item.id }` } callInfo={ item as ICallNotification }/>
    }
    if (item.consultation_type.includes('conversation') && item.hasOwnProperty('conversation_pinged')) {
      return <TextChatNotification id={ `${ item.id }` } conversationInfo={ item as IConversationNotification }/>
    }
    if (item.consultation_type === 'message') {
      const historyItem = item as IHistory;
      return <>
        <Request id={ `${ item.id }` } history={ item as IHistory } isActiveCounsellor={ isActiveCounsellor }
                 onResend={ onResendMessageHandler }/>
        { (!historyItem.is_replied && historyItem.sender_type === 'user') && getInfoMessage(historyItem) }
      </>
    }
    if (item.consultation_type === 'conversation') {
      return <ConversationConsultation id={ `${ item.id }` } history={ item as IConversationHistory }/>
    }
    return <Consultation id={ `${ item.id }` } history={ item as IHistory }/>
  }

  const scrollToMessageById = (id: number, duration: number = 2000): void => {
    const element = document.getElementById(`${ id }`);
    if (!element) return;

    element.scrollIntoView({ behavior: "smooth", block: 'center' });
    element.classList.add('show-active');

    const timerId = setTimeout(() => {
      element.classList.remove('show-active');
      clearTimeout(timerId);
      if (!searchParams.has('id')) return;
      setSearchParams({});
    }, duration);
  }

  useEffect(() => {
    if (!searchParams.has('id') || loading || !history.length) return;
    const id = Number(searchParams.get('id'));
    scrollToMessageById(id, 3000);
  }, [searchParams, loading, history]);

  return (
    <div className="history-list">
      {
        !!history.length && <div className="history-list--body" onScroll={ onScrollListHandler }>
          {
            Object.keys(transformDatesObject).map((date) => {
              return <Fragment key={ date }>
                {
                  transformDatesObject[date].map((item) =>
                    <div className="body-item" key={ item.history_id }>
                      { getComponentByType(item) }
                    </div>
                  )
                }
                <div className="body-item body-item--today">{ getDateTitle(date) }</div>
              </Fragment>
            })
          }
        </div>
      }

      {
        (!history.length && !loading) && <div className="history-list--empty">
          <div className="empty-img">
            <img src={ '/assets/icons/big/empty-history-body.svg' } alt={ 'empty-icon' }/>
          </div>
          <div className="empty-subtitle">
            You don’t have any history with this Consultant yet. Start communicating!
          </div>
        </div>
      }
    </div>
  );
};
