import React from 'react';

// Components
import { CallNotifications } from '../modules/call-notifications/call-notifications';

// Styles
import './status-unavailable-no-payment.scss';

export const StatusUnavailableNoPayment = () => {

  const workerId = localStorage.getItem('worker_id');
  const returnPathname = localStorage.getItem('return_pathname') ?? `/expert/${workerId}`;

  return (<CallNotifications
    title={'Availability Issue'}
    text={'The Consultant is no longer available. You may try to contact them later.'}
    navigateAction={returnPathname}  />);
}
