import React from 'react';

// Components
import { ConversationNotifications } from '../modules/conversation-notifications/conversation-notifications';

// Styles
import './chat-status-declined.scss';

export const ChatStatusDeclined = () => {

  const workerId = localStorage.getItem('worker_id');

  return (<ConversationNotifications
    title={'Consultant Unavailable'}
    text={'The Consultant declined the connection. You may try to contact them later. The payment for the Text Chat will be refunded to you.'}
    navigateAction={`/expert/${workerId}/history`}  />);
}
