import { FC } from 'react';
import dayjs from 'dayjs';
import Linkify from 'linkify-react';

// Shared UI Library Components
import { UserAvatar } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './message.scss';

interface IMessageProps {
  isMy: boolean;
  message: string;
  avatarUrl?: string | null;
  name?: string;
  message_time?: string;
}

export const Message: FC<IMessageProps> = (
  { isMy, message, avatarUrl, name = 'Consultant', message_time }: IMessageProps
) => {

  return (
    <div className={ `message-list--item ${ isMy ? 'my-message' : '' }` }>
      <div className="item-avatar">
        <UserAvatar sizeClass={'small'} avatarUrl={ avatarUrl } hasVideo={ false }/>
      </div>
      <div className="item-info">
        <div className="item-info--date">
          { name }, { dayjs(message_time).format('hh:mm A') }
        </div>
        <div className="item-info--message">
          <Linkify options={ { target: '_blank' } }>{ message }</Linkify>
        </div>
      </div>
    </div>
  );
};
