import React from 'react';

// Components
import { NetworkDisconnected } from '../modules/network-disconnected/network-disconnected';

// Styles
import './status-network-disconnected-counsellor.scss';

export const StatusNetworkDisconnectedCounsellor = () => {
  return (<NetworkDisconnected title={'Oops, something went wrong on the Consultant side'} />);
}
