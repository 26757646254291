import React, { FC, ReactNode } from 'react';
import dayjs from 'dayjs';

// Shared UI Library Components
import { CustomButton, CustomIcon, EIconName, Loader, tzDayjs } from '@ppmcore/seven-ppm-core-shared-components-react';

// Styles
import './notification-item.scss';

// Models
import { INotificationItem } from '../../../../interfaces/notifications.interfaces';

type TNotificationItemProps = {
  notification: INotificationItem;
  loading?: boolean;
  onDelete?: (id: number) => void
  onView?: (notification: INotificationItem) => void
};

export const NotificationItem: FC<TNotificationItemProps> = (
  {
    notification,
    loading = false,
    onDelete = () => {},
    onView = () => {}
  }: TNotificationItemProps
) => {

  const isHideViewAction = notification.notification_type === 'worker_deactivated' || notification.notification_type === 'worker_blocked';

  const getActionTextByType = (): ReactNode => {
    const workerName = `${ notification.worker?.first_name } ${ notification.worker?.last_name }`;
    const dateString = notification.hasOwnProperty('appointment_notified') ?
      `${ tzDayjs(notification.appointment_notified?.started_at).format('dddd, MMM D [at] hh:mm A') }
       -
       ${ tzDayjs(notification.appointment_notified?.finished_at).format('hh:mm A') }`
      : '';

    const actionTexts: { [key: string]: ReactNode } = {
      refund: <>New Response to Request to Refund
        from { dayjs(notification.notification_created_at).format('DD MMM YYYY [at] hh:mm A') } from <span
          className="bold-text">{ workerName }</span></>,
      message_ping: <>You received a ping from <span className="bold-text">{ workerName }</span></>,
      call_ping: <>You received a ping from <span className="bold-text">{ workerName }</span></>,
      conversation_ping: <>You received a ping from <span className="bold-text">{ workerName }</span></>,
      worker_deactivated: <>Deactivated Consultant <span className="bold-text">{ workerName }</span> from Favorites
        list</>,
      worker_blocked: <>Blocked Consultant <span className="bold-text">{ workerName }</span> from Favorites list</>,
      appointment_notification: <>New <span className="bold-text">Video Call</span> Appointment Schedule Request
        from { workerName } for { dateString }</>,
      appointment_canceled: <>New <span className="bold-text">Video Call</span> Appointment scheduled
        from { workerName } for { dateString } has been canceled</>,
      appointment_approved: <>New <span className="bold-text">Video Call</span> Appointment scheduled
        from { workerName } for { dateString } has been approved</>,
      appointment_reminder: <>New <span className="bold-text">Video Call</span> You have a Appointment
        with { workerName } for { dateString }. Don't miss it!</>,
    };
    return actionTexts[notification.notification_type] || <><span>New Response to Request
      from { dayjs(notification.notification_created_at).format('DD MMM YYYY [at] hh:mm A') } from</span><span
      className="bold-text">{
      `${ workerName }`
    }</span></>;
  }

  const onDeleteHandler = (): void => {
    if (loading) return;
    onDelete(notification.id);
  }

  const onViewHandler = (): void => {
    onView(notification);
  }

  return (
    <div className={ `notification-item ${ notification.is_read ? '' : 'notification-item--unread' }` }>
      <div className="notification-item--info">
        <div className="info-item info-item--title">
          { getActionTextByType() }
        </div>
        <div className="info-item info-item--date">
          <div className="logo-item">
            <CustomIcon size={ 'size-md' } name={ EIconName.BusinessCenterFill }/>
            { <span>{ notification.company?.company_name ?? '---' }</span> }
          </div>
          <div className="date-item">
            { dayjs(notification.notification_created_at).format('DD MMM YYYY, hh:mm A') }
          </div>
        </div>
      </div>
      <div className="notification-item--actions">
        { !isHideViewAction && <div className="actions-item actions-item--view">
          <CustomButton text={ 'View' } type={ 'text' } size={ 'small' } onClick={ onViewHandler }/>
        </div> }
        <div className="actions-item actions-item--delete" onClick={ onDeleteHandler }>
          { loading ? <Loader/> : <CustomIcon name={ EIconName.Close }/> }
        </div>
      </div>
    </div>
  );
}
